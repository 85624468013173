import React, { useEffect, useState } from "react";
import { LandingWhiteSec } from "../../shared-styled-components";
import * as S from "./LandingTestimonials.style";
import SectionDividers from "../SectionDividers/SectionDividers";
import { Image } from "../Image";

const LandingTestimonialsMobile = ({
  title,
  data,
  starEmpty,
  starFull,
}: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(data?.length);

  const [touchPosition, setTouchPosition] = useState<number | null>(null);

  useEffect(() => {
    setLength(data?.length);
  }, [data]);

  const next = () => {
    if (currentIndex < length - 1) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      next();
    }

    if (diff < -5) {
      prev();
    }

    setTouchPosition(null);
  };
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setCurrentIndex((prevIndex: any) =>
          prevIndex === data.length - 1 ? 0 : prevIndex + 1
        ),
      25000
    );

    return () => {
      resetTimeout();
    };
  }, [currentIndex]);

  return (
    <>
      <LandingWhiteSec>
        <S.LandingTestimonials>
          <S.LandingTestTitle>{title}</S.LandingTestTitle>
          <S.SlideShowContainer
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            <S.SlideShowSLider
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {data &&
                data.map((item: any, index: number) => {
                  return (
                    <S.LandingTest key={index}>
                      <S.Flex>
                        <S.LandingTestImg>
                          <Image width="84" height="87" src={item.personImg} />
                        </S.LandingTestImg>
                        <div>
                          <S.PersonName>{item.personName}</S.PersonName>
                          <S.StarRatingWrap>
                            <S.StarRating>
                              {Array.from({ length: 5 }, (_, index) => (
                                <Image key={index} {...starEmpty} />
                              ))}
                            </S.StarRating>
                            <S.StarRating className="starRateFull">
                              {Array.from(
                                { length: item.personRating },
                                (_, index) => (
                                  <Image key={index} {...starFull} />
                                )
                              )}
                            </S.StarRating>
                          </S.StarRatingWrap>
                          <S.LandingTextCrd
                            className={`${item.personCrdClass}`}
                          >
                            {item.personCard}
                          </S.LandingTextCrd>
                        </div>
                      </S.Flex>

                      <S.LandingTestCntnt>
                        <p>{item.testInfo}</p>
                      </S.LandingTestCntnt>
                    </S.LandingTest>
                  );
                })}
            </S.SlideShowSLider>
          </S.SlideShowContainer>
          <S.SlideDots className="slideshowDots">
            {data.map((_: any, idx: number) => (
              <div
                key={idx}
                className={`slideshowDot${
                  currentIndex === idx ? " active" : ""
                }`}
                onClick={() => {
                  setCurrentIndex(idx);
                }}
              ></div>
            ))}
          </S.SlideDots>
        </S.LandingTestimonials>
      </LandingWhiteSec>
      <SectionDividers small />
    </>
  );
};

export default LandingTestimonialsMobile;
