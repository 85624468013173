import styled from "styled-components";
import { OnCardTag } from "../OnCardTag";

export const LandingTestimonials = styled.div`
  display: flex;
  background: #fff;
  flex-direction: column;
  margin-bottom: 3rem;
`;

export const LandingTestTitle = styled.h3`
  font-family: "Raleway";
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 34px;
  display: flex;
  align-items: center;
`;

export const LandingTest = styled.div`
  flex: 0 0 100%;
  font-weight: 400;
  color: #4a4a4a;
  display: inline-block;
  align-items: flex-start;
  border-radius: 10px;
  position: relative;
  background: #fff;
  z-index: 1;
  width: 100% !important;
  flex-direction: column !important;
  padding: 10px;
  font-size: 14px;
  line-height: 20px;
`;

export const LandingTestImg = styled.div`
  width: 84px;
  height: 87px;
  display: flex;
  flex-shrink: 0;
  position: relative;
  margin-right: 20px;
  z-index: 1;
`;

export const LandingTextCrd = styled(OnCardTag)``;

export const PersonName = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #012136;
  margin-bottom: 2px;
  display: block;
`;

export const LandingTestCntnt = styled.div`
  z-index: 1;
  white-space: initial;
`;

export const Flex = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
`;

export const SlideShowContainer = styled.div`
  margin: 0 auto;
  overflow: hidden;
  max-width: 100%;
  cursor: grab;
`;

export const SlideShowSLider = styled.div`
  white-space: nowrap;
  transition: ease 1000ms;
  display: flex;
  align-items: top;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SlideDots = styled.div`
  text-align: center;

  .slideshowDot {
    background: #3f3d56;
    opacity: 0.2;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    display: inline-block;
    margin-right: 16px;
    cursor: pointer;
  }

  .slideshowDot.active {
    width: 32px;
    height: 12px;
    background: #000000 !important;
    opacity: 1;
    border-radius: 6px;
  }
`;

export const StarRating = styled.div`
  display: flex;
  gap: 5px;

  &.starRateFull {
    position: absolute;
    top: 0;
  }
`;

export const StarRatingWrap = styled.div`
  margin-bottom: 10px;
  position: relative;
`;
