import styled from "styled-components";

export const SectionDividers = styled.div<any>`
  background: #f2f2f2;

  ${(p) =>
    p.small &&
    `
    height:10px;
    `}
  ${(p) =>
    p.large &&
    `
    height:25px;
    `}
`;
